// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chi-siamo-tsx": () => import("./../../../src/pages/chi-siamo.tsx" /* webpackChunkName: "component---src-pages-chi-siamo-tsx" */),
  "component---src-pages-consigli-di-pulito-tsx": () => import("./../../../src/pages/consigli-di-pulito.tsx" /* webpackChunkName: "component---src-pages-consigli-di-pulito-tsx" */),
  "component---src-pages-contattaci-tsx": () => import("./../../../src/pages/contattaci.tsx" /* webpackChunkName: "component---src-pages-contattaci-tsx" */),
  "component---src-pages-il-nostro-impegno-tsx": () => import("./../../../src/pages/il-nostro-impegno.tsx" /* webpackChunkName: "component---src-pages-il-nostro-impegno-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-prodotti-tsx": () => import("./../../../src/pages/prodotti.tsx" /* webpackChunkName: "component---src-pages-prodotti-tsx" */),
  "component---src-templates-prodotto-tsx": () => import("./../../../src/templates/prodotto.tsx" /* webpackChunkName: "component---src-templates-prodotto-tsx" */),
  "component---src-templates-prodotto-wp-tsx": () => import("./../../../src/templates/prodotto_wp.tsx" /* webpackChunkName: "component---src-templates-prodotto-wp-tsx" */)
}

